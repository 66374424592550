<template>
  <div class="fast-search">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container>
      <Breadcrumb :items="breadcrumb" v-if="$vuetify.breakpoint.smAndUp" />
      <CategoryTitle v-if="category" :category="category" />

      <div class="search-result" v-for="q in query" :key="q">
        <SimpleProductListSlider
          :query="q"
          :paginationClass="'swiper-pagination-' + q"
        />
        <v-divider class="mb-6"></v-divider>
      </div>
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<script>
import SimpleProductListSlider from "@/components/product/SimpleProductListSlider.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import AnalyticsService from "@/commons/service/analyticsService";
import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    SimpleProductListSlider,
    CategoryTitle,
    Breadcrumb
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: []
    };
  },
  methods: {
    getQueryParams() {
      let q = this.$route.query.q;
      if (typeof q === "string") {
        this.query.push(q);
      } else {
        this.query = this.$route.query.q;
      }
      if (q && q.length) {
        for (const term of q) {
          AnalyticsService.search(term, "fast-search");
        }
      }
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
